<template>
  <div class="column panel-fatal-elem">
    <div class="columns head">
      <div class="column">
        <h2>{{ $t('panels.fatal.headline') }}</h2>
        <p>{{ $t('panels.fatal.text') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import fn from '../../services/GlobalService'
/**
 * errno: 1
 */
export default {
  name: 'panel-fatal-elem'
}
</script>

<style lang="sass">
</style>
