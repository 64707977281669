<template>
  <div class="column panel-multi-elem">
    <div class="columns head">
      <div class="column">
        <h2>{{ $t('panels.multi.headline') }}</h2>
        <p>{{ $t('panels.multi.text') }}</p>
      </div>
    </div>
    <div class="columns">
      <div class="column table">
        <div class="columns table-head is-mobile">
          <div class="column">{{ $t('panels.multi.col1') }}</div>
          <div class="column">{{ $t('panels.multi.col2') }}</div>
          <div class="column"></div>
        </div>
        <div
          class="columns table-head is-mobile"
          v-for="(adj, n) in $store.getters.currentDoor.multi"
          v-bind:key="n">
            <div class="column value">{{ adj[0] }} {{ $t('result.kb.unit') }}</div>
            <div class="column value">{{ adj[1] }} {{ $t('result.kt.unit') }}</div>
            <div class="column action">
              <button
                  class="button is-primary is-small"
                  role="button"
                  v-on:click="onClick(adj)">
                    {{ $t('panels.multi.button') }}
                </button>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fn from '../../services/GlobalService'

/**
 * errno: 16
 * only calc = shaft
 *
 * Structure of multi, similar to adjustments
 * [kb, kt, false, false]
 *
 * Not the input values are changed by this function, but one of multiple
 * result is selected. Therefore the desired results are given as an
 * extra parameter 'shaft' to calculation funtion.
 */
export default {
  name: 'panel-multi-elem',
  methods: {
    onClick (adj) {
      this.$store.commit('selectResultValues', {
        kb: adj[0],
        kt: adj[1]
      })
    }
  }
}
</script>

<style lang="sass">
</style>
