<template>
  <div class="calc-page">
    <component v-bind:is="'tipps-segement'" v-bind:key="componentsKeyTipps"></component>
    <product-select-segment></product-select-segment>
    <component v-bind:is="'inputs-segment'" v-bind:key="componentsKeyInputs"></component>
    <component v-bind:is="'result-segment'" v-bind:key="componentsKeyResult"></component>
  </div>
</template>

<script>
import fn from '../../services/GlobalService'
import TippsSegment from '../segments/TippsSegment'
import ProductSelectSegment from '../segments/ProductSelectSegment'
import InputsSegment from '../segments/InputsSegment'
import ResultSegment from '../segments/ResultSegment'

export default {
  name: 'calc-page',
  components: {
    'tipps-segement': TippsSegment,
    'product-select-segment': ProductSelectSegment,
    'inputs-segment': InputsSegment,
    'result-segment': ResultSegment
  },
  computed: {

    /**
     * IMPORTANT:
     * This componentsKey changes each time when the user changes the calculation method
     * or the product. It forces the component to re-render, doing all that initialization
     * stuff! Without this re-rendering the different config files, which are loaded to
     * in the store, would have no effect on the interface elements.
     */
    componentsKeyTipps (component) {
      return this.$store.state.calc + '-' + this.$store.state.product + '-overlay'
    },
    componentsKeyInputs (component) {
      return this.$store.state.calc + '-' + this.$store.state.product + '-inputs'
    },
    componentsKeyResult (component) {
      return this.$store.state.calc + '-' + this.$store.state.product + '-result'
    }
  }
}
</script>

<style lang="sass">
</style>
